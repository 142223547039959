import React, { useState, useEffect } from 'react';
import NumberFlow from '@number-flow/react';

const Tracker = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [milesWalked, setMilesWalked] = useState(0);
  const [pace, setPace] = useState(2);
  const [tempMilesWalked, setTempMilesWalked] = useState(0);
  const [tempPace, setTempPace] = useState(2);
  const [history, setHistory] = useState([]);
  const [sessionMilesWalked, setSessionMilesWalked] = useState(0); // Daily session miles
  const [isDay, setIsDay] = useState(true);
  const [position, setPosition] = useState(0);
  const [greetingH1, setGreetingH1] = useState('');
  const [greetingH3, setGreetingH3] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const backendApiPath = process.env.REACT_APP_API_PATH;

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching tracking data...');
        const response = await fetch(`${backendURL}/${backendApiPath}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('Received tracking data:', responseData);

        if (responseData) {
          setIsRunning(responseData.isRunning);
          setMilesWalked(responseData.milesWalked); // Total miles walked
          setSessionMilesWalked(responseData.sessionMiles); // Daily session miles
          setPace(responseData.pace);
          setTempMilesWalked(responseData.milesWalked);
          setTempPace(responseData.pace);
        }
      } catch (error) {
        console.error('Error fetching tracking data:', error);
      }
    };

    const fetchIsRunning = async () => {
      try {
        console.log('Fetching isRunning status...');
        const response = await fetch(`${backendURL}/${backendApiPath}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('Received isRunning status:', responseData.isRunning);

        if (responseData.isRunning !== isRunning) {
          setIsRunning(responseData.isRunning);
        }

        if (responseData.isRunning) {
          // Fetch full data if isRunning is true
          setMilesWalked(responseData.milesWalked);
          setSessionMilesWalked(responseData.sessionMiles); // Update session miles walked
          setPace(responseData.pace);
        }
      } catch (error) {
        console.error('Error fetching isRunning status:', error);
      }
    };

    fetchData(); // Fetch full data on initial load

    const intervalId = setInterval(fetchIsRunning, 3000); // Fetch isRunning status every 3 seconds

    return () => clearInterval(intervalId);
  }, [backendURL, backendApiPath, isRunning]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetch(`${backendURL}/${backendApiPath}/history`);
        if (!response.ok) {
          throw new Error('Failed to fetch history data');
        }
        const data = await response.json();
        setHistory(data); // Store the data in state
      } catch (error) {
        console.error('Error fetching history data:', error);
      }
    };

    fetchHistory();
  }, [backendURL, backendApiPath]);

  const updateValues = async (newValues) => {
    try {
      console.log('Updating values on the server...');
      const response = await fetch(`${backendURL}/${backendApiPath}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isRunning: newValues.isRunning ?? isRunning,
          milesWalked: newValues.milesWalked ?? milesWalked,
          sessionMiles: newValues.sessionMilesWalked ?? sessionMilesWalked,
          pace: newValues.pace ?? pace,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        console.log('Values updated successfully on the server.');
        if (newValues.isRunning !== undefined) setIsRunning(newValues.isRunning);
        if (newValues.milesWalked !== undefined) setMilesWalked(newValues.milesWalked);
        if (newValues.sessionMilesWalked !== undefined) setSessionMilesWalked(newValues.sessionMilesWalked);
        if (newValues.pace !== undefined) setPace(newValues.pace);

        localStorage.setItem('isRunning', newValues.isRunning ?? isRunning);
        localStorage.setItem('milesWalked', newValues.milesWalked ?? milesWalked);
        localStorage.setItem('sessionMilesWalked', newValues.sessionMilesWalked ?? sessionMilesWalked);
        localStorage.setItem('pace', newValues.pace ?? pace);
      } else {
        console.error('Failed to update values on the server:', data.error);
      }
    } catch (error) {
      console.error('Error updating values on the server:', error);
    }
  };

  // Debounced effect for updating the pace on the back end
  useEffect(() => {
    const updatePaceOnBackend = async () => {
      try {
        console.log('Updating pace on the server...');
        const response = await fetch(`${backendURL}/${backendApiPath}/updatePace`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pace: tempPace }),
        });

        if (!response.ok) {
          throw new Error('Failed to update pace on the server');
        }

        const data = await response.json();
        if (data.success) {
          console.log('Pace updated successfully on the server.');
          setPace(tempPace); // Update the pace state after a successful back-end update
        } else {
          console.error('Failed to update pace on the server:', data.error);
        }
      } catch (error) {
        console.error('Error updating pace on the server:', error);
      }
    };

    const debounceTimeout = setTimeout(updatePaceOnBackend, 500); // Wait 500ms before updating the back end
    return () => clearTimeout(debounceTimeout); // Clear the timeout if the user updates the pace again
  }, [tempPace, backendURL, backendApiPath]);

  useEffect(() => {
    const updateUI = () => {
      // Check and update the greeting
      const { h1, h3 } = getGreeting();
      setGreetingH1(h1);
      setGreetingH3(h3);

      // Check and update the day/night mode
      const { isDay: currentIsDay, position: currentPosition } = getDayNightMode();
      setIsDay(currentIsDay);
      setPosition(currentPosition);
    };

    // Run the update immediately on mount
    updateUI();

    // Set up an interval to run the update every 5 minutes
    const intervalId = setInterval(updateUI, 300000); // 300,000 ms = 5 minutes

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleStartPauseClick = () => {
    const newIsRunning = !isRunning;
    updateValues({ isRunning: newIsRunning });
  };

  const handlePaceInputChange = (event) => {
    const newPace = parseFloat(event.target.value);
    if (!isNaN(newPace)) {
      setTempPace(newPace); // Update the temporary pace
    }
  };

  const handleMilesWalkedInputChange = (event) => {
    const newMilesWalked = parseFloat(event.target.value);
    if (!isNaN(newMilesWalked)) {
      setTempMilesWalked(newMilesWalked);
    }
  };

  const handleUpdateClick = () => {
    updateValues({
      milesWalked: tempMilesWalked,
      pace: tempPace,
    });
  };

  // const resetMilesWalked = () => {
  //   updateValues({ isRunning: false, milesWalked: 0 });
  // };

  const openOverlayPage = () => {
    window.open('/overlay', '_blank');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
  
    // Check if the date is today
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    }
  
    // Check if the date is yesterday
    if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
  
    // Check if the date is within the past week
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    if (date > oneWeekAgo) {
      return date.toLocaleDateString('en-US', { weekday: 'long' }); // e.g., "Thursday"
    }
  
    // Otherwise, return the full date (e.g., "March 12th")
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    });
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
  
    // Define greetings for different times of the day
    const morningGreetings = [
      "Have a great day, and get those steps in!",
      "Rise and shine! Time to move!",
      "Start your day with a walk!",
    ];
    const afternoonGreetings = [
      "Keep going! You're doing great!",
      "Take a break and enjoy a walk!",
      "Stay active this afternoon!",
    ];
    const eveningGreetings = [
      "Relax and unwind with a walk!",
      "End your day on a high note!",
      "Take a peaceful evening stroll!",
    ];
  
    // Determine the time period and select greetings
    if (currentHour >= 5 && currentHour < 12) {
      return {
        h1: "Good Morning Broman!",
        h3: morningGreetings[Math.floor(Math.random() * morningGreetings.length)],
      };
    } else if (currentHour >= 12 && currentHour < 18) {
      return {
        h1: "Good Afternoon Broman!",
        h3: afternoonGreetings[Math.floor(Math.random() * afternoonGreetings.length)],
      };
    } else {
      return {
        h1: "Good Evening Broman!",
        h3: eveningGreetings[Math.floor(Math.random() * eveningGreetings.length)],
      };
    }
  };

  const getDayNightMode = () => {
    const currentHour = new Date().getHours();
  
    // Determine if it's day or night
    const isDay = currentHour >= 6 && currentHour < 21;
  
    // Calculate the position of the object
    let position = 0;
    if (isDay) {
      // Day mode: 6 AM to 6 PM
      position = ((currentHour - 6) / 15) * 100; // Map 6 AM to 6 PM to 0% to 100%
    } else {
      // Night mode: 6 PM to 6 AM
      const adjustedHour = currentHour >= 21 ? currentHour - 21 : currentHour + 3; // Map 6 PM to 6 AM to 0 to 12
      position = (adjustedHour / 15) * 100; // Map 0 to 12 to 0% to 100%
    }
  
    return { isDay, position };
  };

  return (
    <div className="app-container">
      <div className="tracker-layout">
        <div
          className={`hero ${isDay ? 'day-mode' : 'night-mode'}`}
          style={{ '--object-position': `${position}%` }}
        >
          <div className="content-block">
            <div className="intro">
              <h1 className="greeting">{greetingH1}</h1>
              <h3 className="greeting-support">{greetingH3}</h3>
            </div>
            <div className="running-bald">
              <img
                src={isRunning ? "/images/bromnrun.gif" : "/images/bromnrun-pause.png"}
                alt={isRunning ? "Running" : "Paused"}
              />
            </div>
          </div>
        </div>

        <div className='miles-walked'>
          <div className='mile-counters'>
            <div className='total-miles'>
              <h4 className="title">
                <NumberFlow
                  value={milesWalked} // Animate total miles walked
                  formatValue={(value) => value.toFixed(3)} // Format to 3 decimal places
                  duration={1000} // Animation duration in milliseconds
                  easing="easeOutCubic" // Easing function for smooth animation
                />
              </h4>
              <p>Total Miles Walked</p>
            </div>

            <div className='session-miles'>
              <h4 className="subtitle">
                <NumberFlow
                  value={sessionMilesWalked} // Animate session miles walked
                  formatValue={(value) => value.toFixed(3)} // Format to 3 decimal places
                  duration={1000} // Animation duration in milliseconds
                  easing="easeOutCubic" // Easing function for smooth animation
                />
              </h4>
              <p>Today's Miles</p>
            </div>
          </div>
          
          {console.log('sessionMilesWalked:', sessionMilesWalked)}
        </div>

        <div className='controls'>
          <div className='content-block'>
            <div className='toggle-pause'>
              <button
                id="startPauseButton"
                onClick={handleStartPauseClick}
                className={isRunning ? 'paused' : 'running'}
              >
                {isRunning ? 'Pause' : 'Start'}
              </button>
            </div>
            
          </div>
        </div>

        
        <div className='options'>
          <div className='content-block'>
            <div className='set-pace'>
              <div className='option'>
                <label htmlFor="paceInput">Set Pace (mph):</label>
                <div className="custom-stepper">
                  <button
                    type="button"
                    className="stepper-button increment"
                    onClick={() => setTempPace((prev) => prev + 0.1)} // Increment pace
                  >
                    <img src='/images/plus-icon.svg' className='icon' alt='Increase Pace'/>
                  </button>
                  <input
                    type="number"
                    id="paceInput"
                    value={tempPace.toFixed(1)}
                    onChange={handlePaceInputChange}
                    min="0"
                    step="0.1"
                    className="the-pace"
                    readOnly // Prevent manual input
                  />
                  <button
                    type="button"
                    className="stepper-button decrement"
                    onClick={() => setTempPace((prev) => Math.max(0, prev - 0.1))} // Decrement pace
                    
                  >
                    <img src='/images/minus-icon.svg' className='icon' alt='Reduce Pace'/>
                  </button>
                </div>
              </div>
            </div>
            {isRunning ? null : (
              <div className='edit-miles'>
                <div className='option'>
                  <label htmlFor="editableMilesInput">Set Starting Miles:</label>
                  <input
                    type="number"
                    id="editableMilesInput"
                    value={tempMilesWalked.toFixed(3).replace(/\.?0+$/, '')}
                    onChange={handleMilesWalkedInputChange}
                    min="0"
                    step="0.001"
                    className='starting-miles'
                  />
                  <button onClick={handleUpdateClick}><img src='/images/save-icon.svg' className='icon' alt='Set Miles'/></button>
                </div>
              </div>
            )}
            </div>
          </div>
      </div>

      <div className="history-layout">
        <div className='content-block'>
          <h3>Miles Ledger</h3>
          {history.length === 0 ? ( // Check if the history array is empty
            <p className="no-history-message">Nothing logged, get walking!</p>
          ) : (
            <table className="history-table">
              <thead>
                <tr className='history-row-header'>
                  <th>Date</th>
                  <th>Daily Session Miles</th>
                </tr>
              </thead>
              <tbody>
                {history.map((entry) => (
                  <tr key={entry.id} className='history-row'>
                    <td>{formatDate(entry.date)}</td>
                    <td className='history-session-miles'>{entry.session_miles.toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <footer>
        <div className='content-block'>
          <button onClick={openOverlayPage}>Open Overlay Page</button>
          {/* <button onClick={resetMilesWalked}>Reset</button> */}
          <p>v 2.0</p>
        </div>
      </footer>
    </div>
  );
};

export default Tracker;
