import React, { useState, useEffect } from 'react';
import NumberFlow from '@number-flow/react'

const OverlayPage = () => {
  const [isRunning, setIsRunning] = useState(false); // Define isRunning state
  const [milesWalked, setMilesWalked] = useState(0); // Total miles walked
  const [sessionMilesWalked, setSessionMilesWalked] = useState(0); // Session miles walked
  const [showSessionMiles, setShowSessionMiles] = useState(false); // Toggle between total and session miles
  const [isLoading, setIsLoading] = useState(true); // Loading state for initial load
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const backendApiPath = process.env.REACT_APP_API_PATH;

  useEffect(() => {
    let isFirstFetch = true; // Track if this is the first fetch

    const fetchMilesWalked = async () => {
      try {
        if (isFirstFetch) {
          setIsLoading(true); // Only set loading state for the first fetch
        }

        const response = await fetch(`${backendURL}/${backendApiPath}`);
        const data = await response.json();
        if (data) {
          if (data.milesWalked !== undefined) setMilesWalked(data.milesWalked);
          if (data.sessionMiles !== undefined) setSessionMilesWalked(data.sessionMiles);
          if (data.isRunning !== undefined) setIsRunning(data.isRunning);
        } else {
          console.error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error fetching miles walked:', error);
      } finally {
        if (isFirstFetch) {
          setIsLoading(false); // Stop loading after the first fetch
          isFirstFetch = false; // Mark the first fetch as complete
          setShowSessionMiles(false); // Ensure total miles is shown on initial load
        }
      }
    };

    // Fetch data every second
    const interval = setInterval(fetchMilesWalked, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [backendURL, backendApiPath]);

  useEffect(() => {
    const toggleMilesDisplay = () => {
      setShowSessionMiles(true); // Show session miles for 30 seconds
      setTimeout(() => {
        setShowSessionMiles(false); // Switch back to total miles for 1 minute
      }, 30000); // 30 seconds for session miles
    };

    // Start the cycle: Show total miles for 1 minute, then session miles for 30 seconds
    const interval = setInterval(toggleMilesDisplay, 90000); // 1.5-minute cycle (1 minute + 30 seconds)
    setShowSessionMiles(false); // Ensure total miles is shown initially
    toggleMilesDisplay(); // Trigger the first toggle immediately

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='the-overlay'>
      <div className='the-runner'>
        <img
          src={isRunning ? "/images/bromnrun.gif" : "/images/bromnrun-pause.png"}
          alt={isRunning ? "Running" : "Paused"}
        />
      </div>
      <div className='counter'>
        {isLoading ? ( // Show loading message only during the initial load
          <h1 className='overlay-counter'>Loading...</h1>
        ) : (
          <h1 className='overlay-counter'>
            <NumberFlow
              value={showSessionMiles ? sessionMilesWalked : milesWalked} // Animate between session and total miles
              formatValue={(value) => value.toFixed(2)} // Format the number to 2 decimal places
              duration={1000} // Animation duration in milliseconds
              easing="easeOutCubic" // Easing function for smooth animation
            />
          </h1>
        )}
        <p className='which-miles'>
          {showSessionMiles ? "Today's Miles" : "Miles Walked On Stream"}
        </p>
      </div>
      
      
    </div>
  );
};

export default OverlayPage;